<script lang="ts" setup>
const store = useLivroDiarioAuxiliarStore()
const { modalAdicionarReceita, formNovaReceita } = storeToRefs(store)

const orcamentoStore = useOrcamentoStore()
const paginaDeOrcamento = computed(() => useRoute().fullPath.includes('orcamento'))

const valorVariavel = ref(false)
const valor = ref(0)

function adicionarReceitaAoOrcamento () {
  try {
    useStore().loading = true
    validar()
    const atoExtra = modalAdicionarReceita.value.listaDeServicos.find((atoExtra: Servico) => atoExtra.id === modalAdicionarReceita.value.receitaSelecionada)
    if (valorVariavel.value) {
      atoExtra.valor = valor.value
    }
    atoExtra.quantidade = modalAdicionarReceita.value.quantidade
    if (modalAdicionarReceita.value.editMode) {
      if (orcamentoStore.editMode) {
        orcamentoStore.formNovoOrcamento.alterados.EXTRA = [...orcamentoStore.formNovoOrcamento.alterados.EXTRA, atoExtra.id]
      }
      orcamentoStore.formNovoOrcamento.servicos.EXTRA = orcamentoStore.formNovoOrcamento.servicos.EXTRA.map((ato: Servico) => {
        if (ato.servico_id === atoExtra.id) {
          return atoExtra
        }
        return ato
      })
      useStore().toasts.push({ content: 'Receita editada com sucesso', color: 'success' })
    } else {
      orcamentoStore.adicionarAtoExtra(atoExtra, modalAdicionarReceita.value.quantidade)
    }
    modalAdicionarReceita.value.exibir = false
    modalAdicionarReceita.value.editMode = false
    useStore().toasts.push({ content: 'Receita adicionado com sucesso', color: 'success' })
  } catch (err) {
    useStore().toasts.push({ content: err.message, color: 'danger' })
    console.error(err)
  } finally {
    useStore().loading = false
  }
}

function adicionarReceitaAoPagamento () {
  try {
    useStore().loading = true
    validar()
    const receita = modalAdicionarReceita.value.listaDeServicos.find((receita: Servico) => receita.id === modalAdicionarReceita.value.receitaSelecionada)

    if (valorVariavel.value) {
      receita.valor = valor.value
    }

    receita.quantidade = modalAdicionarReceita.value.quantidade
    if (modalAdicionarReceita.value.editMode) {
      formNovaReceita.value.servicos = formNovaReceita.value.servicos.map((servico: Servico) => {
        if (servico.id === modalAdicionarReceita.value.receitaSelecionada) {
          return receita
        }
        return servico
      })
      modalAdicionarReceita.value.editMode = false
    } else {
      formNovaReceita.value.servicos.push(receita)
    }

    modalAdicionarReceita.value.exibir = false
    valorVariavel.value = false
    valor.value = 0
    useStore().toasts.push({ content: 'Serviço adicionado com sucesso', color: 'success' })
  } catch (err) {
    useStore().toasts.push({ content: err.message, color: 'danger' })
    console.error(err)
  } finally {
    useStore().loading = false
  }
}

function validar () {
  if (!modalAdicionarReceita.value.receitaSelecionada) {
    throw new Error('Selecione um serviço')
  }
  if (!modalAdicionarReceita.value.quantidade) {
    throw new Error('Informe a quantidade')
  }
  return true
}

watch(() => modalAdicionarReceita.value.exibir, async (value) => {
  if (value === false) {
    valorVariavel.value = false
    modalAdicionarReceita.value.receitaSelecionada = {} as Servico
    modalAdicionarReceita.value.quantidade = 1
  } else {
    modalAdicionarReceita.value.listaDeServicos = await store.fetchServicos()
  }
})

watch(() => modalAdicionarReceita.value.receitaSelecionada, (value) => {
  if (value) {
    const servico = modalAdicionarReceita.value.listaDeServicos.find((servico: Servico) => servico.id === value)
    if (servico?.valor > 0) {
      valorVariavel.value = false
    } else {
      valorVariavel.value = true
    }
  }
})

</script>

<template>
  <CModal
    :visible="modalAdicionarReceita.exibir"
    backdrop="static"
    aria-labelledby="importarClienteDeOutroCartorioLabel"
    size="lg"
    @close="() => { modalAdicionarReceita.exibir = false }"
  >
    <CModalHeader>
      <CModalTitle id="importarClienteDeOutroCartorioLabel">
        Lançar receita
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <Multiselect
        v-model="modalAdicionarReceita.receitaSelecionada"
        value-prop="id"
        track-by="id"
        label="nome"
        :options="modalAdicionarReceita.listaDeServicos"
        :searchable="true"
        placeholder="Selecione ou pesquise"
        class="mb-3"
      />

      <CFormInput
        v-if="!valorVariavel"
        id="quantidadeDeAtos"
        v-model.number="modalAdicionarReceita.quantidade"
        type="number"
        label="Quantidade de atos"
        placeholder="Informe um número inteiro"
        aria-describedby="quantidadeDeAtos"
        min="1"
      />

      <div v-if="valorVariavel">
        <label
          class="mt-3 mb-2"
        >
          Valor*
        </label>
        <input
          id="valor"
          v-maska
          type="text"
          label="Valor"
          data-maska="##########,##"
          data-maska-reversed
          class="form-control"
          required
          @maska="valor = $event.detail.masked.replace(',', '.')"
        >
      </div>
    </CModalBody>
    <CModalFooter>
      <CButton color="light" @click="() => modalAdicionarReceita.exibir = false">
        Cancelar
      </CButton>
      <CButton color="success" @click="() => { if (paginaDeOrcamento) { return adicionarReceitaAoOrcamento() }else {adicionarReceitaAoPagamento()} }">
        Salvar
      </CButton>
    </CModalFooter>
  </CModal>
</template>
<style scoped></style>
