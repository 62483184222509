<script lang="ts" setup>
const store = useStore()
const { toasts } = storeToRefs(store)
</script>

<template>
  <CToaster class="p-3" placement="top-end">
    <CToast
      v-for="(toast, index) in toasts"
      :key="index"
      :color="toast.color"
      visible
      class="text-white align-items-center"
    >
      <CToastHeader v-if="toast.title" close-button>
        <span class="me-auto fw-bold">{{ toast.title }}</span>
      </CToastHeader>
      <div class="d-flex">
        <CToastBody>
          {{ toast.content }}
        </CToastBody>
        <CToastClose v-if="!toast.title" class="me-2 m-auto" white />
      </div>
    </CToast>
  </CToaster>
</template>

<style scoped></style>
