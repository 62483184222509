<script setup lang="ts">
const usuarioStore = useUsuarioStore()
const autenticacaoStore = useAutenticacaoStore()
const { usuarioAtual, cartorioAtual, cartorios } = storeToRefs(usuarioStore)
const { token } = storeToRefs(autenticacaoStore)

function selecionarCartorio (id: string) {
  useApiStore().request('/autenticacao/selecionar/' + id)
    .then(function (resposta) {
      if (resposta.token) {
        let cartorio = {}

        if (id === 'admin') {
          cartorio = {
            id: 'admin',
            cns: 'Admin CV',
            cor: 'padrao',
            especialidades: [],
          }
        } else {
          usuarioAtual.value.cartorios.forEach(function (valor) {
            if (valor.id === id) {
              cartorio = valor
            }
          })
        }

        usuarioStore.$patch({
          usuarioAtual: {
            usuario: resposta.usuario,
            cartorio,
          },
        })
        token.value = resposta.token
        useApiStore().request('/lark/calendar') // Cria um calendário se não existir
      }
    })
};
</script>

<template>
  <div class="btn-group me-auto user-select-none">
    <button type="button" class="btn btn-outline-secondary dropdown-toggle text-white" data-bs-toggle="dropdown" aria-expanded="false">
      <a v-if="cartorios.length <= 1" href>{{ cartorioAtual.nome_fantasia ? cartorioAtual.nome_fantasia + ' - ' : '' }} CONT {{ useStore().formatarCns(cartorioAtual.cns) }}</a>
      <a v-if="cartorios.length > 1 && (cartorioAtual.tipo_perfil_organizacao === 'contador')" href uib-dropdown-toggle>{{ cartorioAtual.nome_fantasia ? cartorioAtual.nome_fantasia + ' - ' : '' }} CONT {{ useStore().formatarCns(cartorioAtual.cns) }} &nbsp;<i class="tm-icon zmdi zmdi-chevron-down" /></a>
      <a v-if="cartorios.length > 1 && (cartorioAtual.tipo_perfil_organizacao === 'cartorio' || !cartorioAtual.perfil_organizacao_id)" href uib-dropdown-toggle>{{ cartorioAtual.nome_fantasia ? cartorioAtual.nome_fantasia + ' - ' : '' }} CNS {{ useStore().formatarCns(cartorioAtual.cns) }} &nbsp;<i class="tm-icon zmdi zmdi-chevron-down" /></a>
      <a v-if="cartorios.length > 1 && (cartorioAtual.tipo_perfil_organizacao !== 'contador' && cartorioAtual.perfil_organizacao_id) " href uib-dropdown-toggle>{{ cartorioAtual.nome_fantasia ? cartorioAtual.nome_fantasia + ' - ' : '' }} {{ cartorioAtual.cns.length === 11 ? 'CPF' : 'CNPJ' }} {{ useStore().formatarCpfCnpj(cartorioAtual.cns) }} &nbsp;<i class="tm-icon zmdi zmdi-chevron-down" /></a>
    </button>
    <ul class="dropdown-menu" style="max-height: 300px; overflow-y: auto;">
      <li v-for="cartorio in usuarioAtual.cartorios" :key="cartorio">
        <span v-if="cartorio.cns != cartorioAtual.cns && (cartorio.tipo_perfil_organizacao === 'cartorio' || !cartorio.tipo_perfil_organizacao)" href @click="selecionarCartorio(cartorio.id)">
          <span class="dropdown-item d-flex align-items-center">
            <span class="rounded-bullet" :style="`background-color: ${usuarioStore.getBgCor(cartorio.cor)}`" />
            {{ cartorio.nome_fantasia ? cartorio.nome_fantasia + ' - ' : '' }}
            CNS {{ useStore().formatarCns(cartorio.cns) }}
          </span>

        </span>
        <span v-if="cartorio.cns != cartorioAtual.cns && (cartorio.tipo_perfil_organizacao !== 'cartorio' && cartorioAtual.perfil_organizacao_id)" href @click="selecionarCartorio(cartorio.id)">
          <span class="dropdown-item">
            <span class="ss-skin pull-left" data-current-skin="{{ cartorio.cor }}" />
            {{ cartorio.nome_fantasia ? cartorio.nome_fantasia + ' - ' : '' }}
            {{ cartorio.cns.length === 11 ? 'CPF' : 'CNPJ' }}  {{ useStore().formatarCns(cartorio.cns) }}
          </span>
        </span>
      </li>
    </ul>
  </div>
</template>

<style>
.rounded-bullet {
width: 1rem;
height: 1rem;
display: flex;
border-radius: 50%;
margin-right: 0.25rem;
}
</style>
