<script setup type="ts">
import { CSidebarNav, CNavItem } from '@coreui/vue'

const nav = [
  {
    icon: 'cil-chart-pie',
    text: 'Resumo de Caixa',
    href: '/',
  },
  {
    icon: 'cil-dollar',
    text: 'BPO Financeiro',
    href: '/prestacao-de-contas',
  },
  {
    icon: 'cil-book',
    text: 'Livro Diário Auxiliar',
    href: '/livro-diario-auxiliar',
  },
  // {
  //   icon: 'cil-notes',
  //   text: 'Orçamento',
  //   href: '/orcamentos',
  // }
  {
    icon: 'cil-book',
    text: 'Depósito Prévio',
    href: '/deposito-previo',
  }
]
</script>

<template>
  <div>
    <CSidebarNav>
      <!-- BRAND LOGO -->
      <CNavItem href="/">
        <img src="/img/logo.png" alt="Logo" class="img-fluid logo">
      </CNavItem>

      <CNavItem v-for="item in nav" :key="item.text" :href="item.href">
        <i class="nav-icon icon icon-xl" :class="item.icon" />
        {{ item.text }}
      </CNavItem>
      <CNavItem>
        <div class="nav-link" role="button" @click="useLivroDiarioAuxiliarStore().modalImportarXml.exibir = true">
          <i class="nav-icon icon icon-xl cil-cloud-upload" />
          Importar Receitas
        </div>
      </CNavItem>
    </CSidebarNav>
  </div>
</template>
<style scoped>
.logo{ max-width: 150px; margin: 0 auto 1em auto;}
</style>
