<script setup lang="ts">
import { CBreadcrumbItem, CBreadcrumb } from '@coreui/vue'
const breadcrumbs = ref()
const router = useRouter()

function transformText (text: string): string {
  const last = text.split('/').filter((n: string): string => n).pop()

  if (!last) {
    return ''
  }

  const words = last.split('-')
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1)
  }

  const transformedText = words.join(' ')

  return transformedText
}

function gerarSubRotas (uri: string): string[] {
  const partes = uri.split('/')
  const subRotas = []

  let subRota = ''
  for (let i = 1; i < partes.length; i++) {
    subRota += '/' + partes[i]
    subRotas.push(subRota)
  }

  return subRotas
}

const getBreadcrumbs = () => {
  const inicio = [
    {
      active: false,
      name: 'Início',
      path: '/',
    }
  ]

  if (router.currentRoute.value.fullPath === '/') {
    return [...inicio]
  }

  let fullPath = router.currentRoute.value.fullPath
  if (router.currentRoute.value.params) {
    for (const parametro in router.currentRoute.value.params) {
      fullPath = fullPath.replace(`/${router.currentRoute.value.params[parametro]}`, '')
    }
  }

  if (fullPath[fullPath.length - 1] === '/') {
    fullPath = fullPath.slice(0, -1)
  }

  const subRotas = gerarSubRotas(fullPath)

  const restante = subRotas.map((rota: string) => {
    return {
      active: rota === fullPath,
      name: transformText(rota),
      path: `${router.options.history.base}${rota}`,
    }
  })

  return [...inicio, ...restante]
}

router.afterEach(() => {
  breadcrumbs.value = getBreadcrumbs()
})

onMounted(() => {
  breadcrumbs.value = getBreadcrumbs()
})
</script>
<template>
  <CBreadcrumb class="my-0">
    <CBreadcrumbItem v-for="item in breadcrumbs" :key="item" :href="item.active ? '' : item.path" :active="item.active" class="text-white">
      {{ item.name }}
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<style>
a {
  color: white;
  text-decoration: none;
}

a:hover,
a:focus {
  text-decoration: underline;
}
</style>
