<script setup>
const store = useStore()
const { sidebarVisible } = storeToRefs(store)

</script>

<template>
  <CSidebar
    class="border-end"
    color-scheme="light"
    position="fixed"
    :visible="sidebarVisible"
  >
    <AppSidebarNav />
    <CSidebarFooter class="border-top d-none d-lg-flex">
      <!-- sidebar footer. Logout talvez?  -->
    </CSidebarFooter>
  </CSidebar>
</template>
