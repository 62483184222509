<script setup>
import { CIcon } from '@coreui/icons-vue'
import { CAvatar, CDropdownToggle, CDropdownHeader, CDropdown, CDropdownItem, CDropdownDivider, CDropdownMenu } from '@coreui/vue'
const config = useRuntimeConfig()
const autenticacaoStore = useAutenticacaoStore()
const store = useStore()
const { usuario } = storeToRefs(store)
const { token } = storeToRefs(autenticacaoStore)
import avatar from '@/assets/images/avatars/8.jpg'

function sair () {
  store.$reset()
  useOrcamentoStore().$reset()
  useLivroDiarioAuxiliarStore().$reset()
  useAutenticacaoStore().$reset()
  token.value = ''

  window.location.href = config.public.loginUrl
}
</script>
<template>
  <CDropdown placement="bottom-end" variant="nav-item">
    <CDropdownToggle :caret="false">
      <CAvatar v-if="usuario && usuario.value && usuario.value.foto" :src="avatar" size="md" />
      <CIcon icon="cil-user" size="lg" class="text-white" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader
        component="h6"
        class="bg-body-secondary text-body-secondary fw-semibold mb-2 rounded-top"
      >
        Minha Conta
      </CDropdownHeader>
      <CDropdownItem href="/editar-serventia">
        <CIcon icon="cil-bank" class="me-2" />Editar Perfil da Serventia
      </CDropdownItem>
      <CDropdownDivider />
      <CDropdownItem role="button" @click="sair">
        <CIcon icon="cil-lock-locked" /> Sair
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>
