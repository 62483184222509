<script lang="ts" setup>
const store = useLivroDiarioAuxiliarStore()
const { modalImportarXml } = storeToRefs(store)

const xml = ref(null)

onMounted(async () => {
})

async function importarXml () {
  try {
    const arquivo = xml.value
    if (arquivo) {
      await store.uploadXml(arquivo)
      store.modalImportarXml.exibir = false
      useStore().toasts.push({
        content: 'XML importado com sucesso.',
        color: 'success',
      })
      useStore().key++
    } else {
      console.error('Nenhum arquivo selecionado.')
    }
  } catch (err) {
    const errors = []
    errors.push('Erro ao importar XML.')
    if (err.message.toLowerCase().includes('unique violation')) {
      errors.push('Dados de uma ou mais receitas já existem no sistema.')
    } else {
      errors.push(err.message)
    }
    useStore().toasts.push({
      content: `${errors.join(' ')}`,
      color: 'danger',
    })
  }
}

function handleFileChange (event: Event) {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]
  if (file) {
    xml.value = file
  }
}

</script>

<template>
  <CForm ref="formCadastrarCliente" @submit.prevent="importarXml">
    <CModal
      :visible="modalImportarXml.exibir"
      backdrop="static"
      size="lg"
      @close="() => { modalImportarXml.exibir = false }"
    >
      <CModalHeader>
        <CModalTitle id="importarClienteDeOutroCartorioLabel">
          Importar
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <p>Importar relatório de selos no padrão GISE</p>
        <input
          id="xmlServicos"
          class="form-control"
          type="file"
          label="Escolha o XML para importar"
          required
          @change="handleFileChange"
        >
      </CModalBody>
      <CModalFooter>
        <CButton color="light" @click="() => { modalImportarXml.exibir = false }">
          Cancelar
        </CButton>
        <CButton color="success" type="submit">
          Salvar
        </CButton>
      </CModalFooter>
    </CModal>
  </CForm>
</template>
<style scoped></style>
