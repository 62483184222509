<script setup lang="ts">
import { useColorModes } from '@coreui/vue'

const store = useStore()
const { sidebarVisible } = storeToRefs(store)
const headerClassNames = ref('mb-4 p-0')
const { colorMode, setColorMode } = useColorModes('coreui-free-vue-admin-template-theme')
const usuarioStore = useUsuarioStore()
const { usuarioAtual } = storeToRefs(usuarioStore)

const urlControleDeProcessos = computed(() => {
  const config = useRuntimeConfig()
  const url = config.public.loginUrl.replace('autenticacao', 'jwt') + `/${useAutenticacaoStore().token}?continuar=${config.public.loginUrl.replace('autenticacao', '')}`
  console.log(url)
  return url
})

onMounted(() => {
  document.addEventListener('scroll', () => {
    if (document.documentElement.scrollTop > 0) {
      headerClassNames.value = 'mb-4 p-0 shadow-sm'
    } else {
      headerClassNames.value = 'mb-4 p-0'
    }
  })
})

defineExpose({
  headerClassNames,
  colorMode,
  setColorMode,
})
</script>
<template>
  <CHeader position="sticky" :class="headerClassNames" :style="`background-color: ${usuarioStore.getBgCor(usuarioAtual.cartorio.cor)};`">
    <CContainer class="border-bottom px-4" fluid>
      <CHeaderToggler style="margin-inline-start: -14px" @click="sidebarVisible = !sidebarVisible">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <AppHeaderAppSeletorCartorio />
      <CHeaderNav>
        <CNavItem>
          <CNavLink class="text-white" :href="urlControleDeProcessos">
            <CIcon icon="cilColumns" size="lg" title="Controle de processos" />
          </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <li class="nav-item py-1e">
          <div class="vr h-100 mx-2 text-body text-opacity-75" />
        </li>
        <CDropdown variant="nav-item" placement="bottom-end">
          <CDropdownToggle :caret="false" class="text-white">
            <CIcon v-if="colorMode === 'dark'" icon="cil-moon" size="lg" />
            <CIcon v-else-if="colorMode === 'light'" icon="cil-sun" size="lg" />
            <CIcon v-else icon="cil-contrast" size="lg" />
          </CDropdownToggle>
          <CDropdownMenu>
            <CDropdownItem
              :active="colorMode === 'light'"
              class="d-flex align-items-center"
              component="button"
              type="button"

              @click="setColorMode('light')"
            >
              <CIcon class="me-2" icon="cil-sun" size="lg" /> Modo Claro
            </CDropdownItem>
            <CDropdownItem
              :active="colorMode === 'dark'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('dark')"
            >
              <CIcon class="me-2" icon="cil-moon" size="lg" /> Modo Escuro
            </CDropdownItem>
            <CDropdownItem
              :active="colorMode === 'auto'"
              class="d-flex align-items-center"
              component="button"
              type="button"
              @click="setColorMode('auto')"
            >
              <CIcon class="me-2" icon="cil-contrast" size="lg" /> Auto
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
        <li class="nav-item py-1">
          <div class="vr h-100 mx-2 text-body text-opacity-75" />
        </li>
        <AppHeaderDropdownAccnt />
        <IconesModulos />
      </CHeaderNav>
    </CContainer>
    <CContainer class="px-4 bg-primary" fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>
